(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('EmpregadorEscolherPlanoController', EmpregadorEscolherPlanoController);

    EmpregadorEscolherPlanoController.$inject = ['$uibModalInstance', '$state', 'entity', 'Empregador', 'PlanoCredito'];

    function EmpregadorEscolherPlanoController($uibModalInstance, $state, entity, Empregador, PlanoCredito) {
        var vm = this;

        vm.empregador = entity;
        vm.clear = clear;
        vm.confirmPlano = confirmPlano;
        vm.planoCreditos = [];

        function clear() {
            $uibModalInstance.dismiss('cancel');
        }

        function carregarPlanos() {
            PlanoCredito.query({}, function (resp) {
                console.log("resp", resp)
                vm.planoCreditos = resp;
            }, function (error) {
                console.log("resp", error);
            })
        }

        carregarPlanos();

        function confirmPlano(plano) {
            $uibModalInstance.close(plano);
            $state.go('comprar-saldo', null, {reload: true});
        }

        vm.slickConfig = {
            enabled: true,
            autoplay: false,
            draggable: false,
            dots: true,
            arrows: true,
            method: {},
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                },
                {
                    breakpoint: 1008,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                        arrows: false
                    }
                }

            ]
        };
    }
})();
