(function () {
    'use strict';

    angular
        .module('mySkillsPersonalApp')
        .controller('ProdutoController', ProdutoController);

    ProdutoController.$inject = ['$scope', '$state', 'Produto', '$uibModal', 'Principal', 'ConfirmService', '$sce'];

    function ProdutoController($scope, $state, Produto, $uibModal, Principal, ConfirmService, $sce) {
        var vm = this;
        vm.carregando = true;
        vm.carregarProdutos = carregarProdutos;
        vm.buscarProduto = buscarProduto;
        vm.trustedHtml = trustedHtml;
        vm.linhas = [];

        vm.isAuthenticated = Principal.isAuthenticated;

        vm.slider = {
            min: 0,
            max: 10000,
            options: {
                floor: 0,
                ceil: 40000,
                showSelectionBar: true,
                selectionBarGradient: {
                    from: 'white',
                    to: '#1ab394'
                },
                getPointerColor: function (value) {
                    return '#1ab394';
                }
            },
        };

        function trustedHtml(html) {
            return $sce.trustAsHtml(html);
        }

        function carregarProdutos() {
            vm.carregando = true;

            Produto.query({}, onSuccess);
        }

        carregarProdutos();

        function onSuccess(data, headers) {

            vm.produtos = data;


            var indiceLinha = 0;
            var indiceProduto = 0;

            angular.forEach(vm.produtos, function (produto) {
                if (!vm.linhas[indiceLinha]) {
                    vm.linhas[indiceLinha] = {produtos: []};
                }
                indiceProduto++;
                vm.linhas[indiceLinha].produtos.push(produto);

                if (indiceProduto === 3) {
                    indiceProduto = 0;
                    indiceLinha++;
                }
            });


            /*vm.page = pagingParams.page;*/
        }

        function buscarProduto(produto) {
            abrirTelaDeProduto(produto);
        }

        function abrirTelaDeProduto(produto) {
            $state.go('produto-detail', {id: produto.id});
        }


    }
})();
